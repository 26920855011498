import theme from "./theme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import ToolbarPlugin from "./plugins/ToolbarPlugin"; 
import { $createHeadingNode, HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS, $convertToMarkdownString, $convertFromMarkdownString } from "@lexical/markdown";
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import ActionsPlugin from "./plugins/ActionsPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";

import './editor.css';
import { $createTextNode, $getRoot, EditorState } from "lexical";
import { useCallback, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

function Placeholder() {
  return (
    <div className="editor-placeholder">
      Play around with the Markdown plugin...
    </div> 
  );
}  

const editorConfig = {
  namespace: "editor",
  editorState: () => $convertFromMarkdownString(`
    # Welcome to the Lexical Editor!

    This is a demo of the Lexical Editor. It's a rich text editor that supports Markdown shortcuts.

    ## Markdown shortcuts

    @copyright by walle
  `, TRANSFORMERS),
  theme: theme,
  // Handling of errors during update
  onError(error: Error) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode
  ],
};

const UpdatePlugin = () => {
  const [editor] = useLexicalComposerContext();

  const handleTest = useCallback(() => {
    editor.update(() => {
      const root = $getRoot();
      // const firstChild = root.getFirstChild();
      // const markdown = $convertToMarkdownString(TRANSFORMERS);
      const markdown = `Hello world! + ${Date.now()}\n`
      // root.clear();
      // root.append(
      //   $createCodeNode("markdown").append($createTextNode(markdown))
      // )

      // 创建一个表示 <h1> 的 ElementNode
      const h1Node = $createHeadingNode('h1');

      // 创建一个文本节点并应用加粗样式
      const textNode = $createTextNode(markdown).toggleFormat('bold');

       // 将文本节点作为子节点添加到 h1 节点
      h1Node.append(textNode);

      // 将 h1 节点添加到根节点
      root.append(h1Node);
      root.selectEnd();
    });
  }, [editor]);

  return <button onClick={handleTest}>Update editor state</button>;
};


export default function Editor() {

  const [markdown, setMarkdown] = useState('');
  
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).getJavaScriptData = () => {
    return markdown;
  }

  function onChange(editorState: EditorState) {
    // get the markdown string
    editorState.read(() => {
      const markdown = $convertToMarkdownString(TRANSFORMERS);
      setMarkdown(markdown);
    });
  }

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className="editor-container">
        <ToolbarPlugin />
        <OnChangePlugin onChange={onChange} />
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <AutoFocusPlugin />
          <ListPlugin />
          <LinkPlugin />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
          <CodeHighlightPlugin />
          <UpdatePlugin />
        </div>
        <ActionsPlugin />
      </div>
    </LexicalComposer>
  );
}
