import './App.css'
import Editor from './components/editor/Editor';

function App() {
  return (
    <>
      <Editor />
    </>
  )
}

export default App
